import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
// import 'firebase/analytics'
import 'firebase/performance'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import createStore from './createStore'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/tesoma.css'
import { firebaseConfig, rrfConfig } from './config'
import { createFirestoreInstance } from 'redux-firestore'
import { CookiesProvider } from 'react-cookie'
import './i18n'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

try {
  firebase.initializeApp(firebaseConfig)
  firebase.performance()
} catch (err) {}

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
})

const store = createStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
          <CssBaseline />
            <App />
          </ThemeProvider>
        </CookiesProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
