import React, { useState, useEffect } from 'react'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button, Spinner, Modal, ModalBody, Badge } from 'reactstrap'

import { Container, Grid, Paper, Box, TextField, Typography } from '@mui/material'
import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import UpdateIcon from '@mui/icons-material/Update'
import TopNavigation from '../TopNavigation'

import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import 'moment/locale/fi'
import { falconInstance } from '../config'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}))

// color: theme.palette.text.secondary,

const withBookings = compose(
  connect((state) => ({
    selectedDate: state.dateselect.selectedDate ? state.dateselect.selectedDate : moment().format('YYYYMMDD'),
  })),
  firestoreConnect((state) => [
    {
      collection: 'indexes',
      doc: 'bookings',
      subcollections: [{ collection: `${falconInstance.accountkey}`, doc: `${state.selectedDate}` }],
      storeAs: `${state.selectedDate}`,
    },
    {
      collection: 'customers',
      doc: `${falconInstance.accountkey}`,
      subcollections: [{ collection: 'resources' }],
      storeAs: 'resources',
    },
  ]),
  // connect(({ firestore }) => {
  //   if (firestore.data['bookings']) {
  // let _m = Object.keys(firestore.data['bookings'])
  //   .map((key) => {
  //     return {
  //       ...firestore.data['bookings'][key],
  //       id: key,
  //       selectkey: key,
  //     }
  //   })
  //   .filter((r) => r.locationid === falconInstance.accountkey && r.state !== 'user_cancelled')
  //   .filter((r) => {
  //     if (r.isrecurring) {
  //       return r.state !== 'cancelled'
  //     } else {
  //       return true
  //     }
  //   })
  //   .map((r) => {
  //     if (r.isrecurring) {
  //       return r.recurrencyDates.map((rec) => {
  //         let _r = JSON.parse(JSON.stringify(r))
  //         let _sKey = _r.selectkey + '-'
  //         if (typeof rec === 'object') {
  //           _r.occurency = { date: rec.date, state: rec.state }
  //           _sKey += rec.date
  //         } else {
  //           _r.occurency = { date: rec }
  //           _sKey += rec
  //         }
  //         _r.selectkey = _sKey // _r.selectkey + '-' + rec
  //         const timeOfDayHelper = moment.utc(_r.resourcegroups[0].end)
  //         const ordering = moment.utc(_r.occurency.date)
  //         ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
  //         if (moment().utcOffset() > 0) {
  //           ordering.subtract(moment().utcOffset(), 'minute')
  //         } else {
  //           ordering.add(moment().utcOffset(), 'minute')
  //         }
  //         _r.ordering = ordering.unix()
  //         return _r
  //       })
  //     } else {
  //       r.ordering = moment.utc(r.resourcegroups[0].end).unix()
  //       return r
  //     }
  //   })
  //   .flat(3)

  // _m.sort(function (a, b) {
  //   if (a.ordering < b.ordering) {
  //     return -1
  //   } else if (a.ordering > b.ordering) {
  //     return 1
  //   } else {
  //     return 0
  //   }
  // })
  // let _onsale = _m
  //   .filter((x) => {
  //     if (x.occurency) {
  //       return x.occurency.state === 'onsale'
  //     } else {
  //       return x.state === 'onsale'
  //     }
  //   })

  // let _past = _m
  //   .filter((x) => x.ordering < moment().unix())
  //   .filter((x) => {
  //     if (x.occurency) {
  //       if (x.occurency.state === 'moved') {
  //         return false
  //       }
  //     }
  //     return true
  //   })
  // let _upcoming = _m
  //   .filter((x) => x.ordering > moment().unix())
  //   .filter((x) => {
  //     if (x.occurency) {
  //       if (x.occurency.state === 'moved') {
  //         return false
  //       }
  //     }
  //     return true
  //   })
  //     return { bookings: firestore.data['bookings'] }
  //     // return { reservations: _upcoming, pastreservations: _past, onsale: _onsale, bookings: firestore.data['bookings'], owns: [] }
  //   } else {
  //     return { bookings: undefined }
  //   }
  // })
  connect((state) => ({
    data: state.firestore.data,
  }))
)

const BookingsScreen = ({ auth, dispatch, data, selectedDate }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const selectDate = (date) => {
    dispatch({
      type: 'UPDATE_DATE',
      payload: date,
    })
  }

  const createTable = (selectedDateBookings, resources, selecteddate) => {
    if (!selectedDateBookings || !resources) return ''
    const filteredResources = Object.keys(resources)
      .filter((key) => resources[key].type === 'court' && resources[key].category !== 'ulkokentät')
      .map((key) => resources[key])
    const unFilteredResources = Object.keys(resources).map((key) => resources[key])
    // filter resources
    filteredResources.sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1
      }
      if (a.displayName > b.displayName) {
        return 1
      }
      return 0
    })

    console.log('selectedDateBookings', selectedDateBookings)
    const bookingIds = Object.keys(selectedDateBookings)
    const aggregated = bookingIds
      .map((id) => JSON.parse(JSON.stringify(selectedDateBookings[id])))
      .reduce((acc, cur) => {
        const resource = unFilteredResources.find((x) => x.id === cur.resourceId)
        if (resource) {
          cur.venue = { name: resource.venue }
        } else {
          cur.venue = { name: 'default' }
        }
        // console.log('cur', cur)
        if (!acc[cur.venue.name]) acc[cur.venue.name] = {}
        if (!acc[cur.venue.name][cur.resourceId]) acc[cur.venue.name][cur.resourceId] = []
        if (
          (cur.title && cur.title.toLowerCase() === 'suljettu') ||
          (cur.data.title && cur.data.title.toLowerCase() === 'suljettu')
        ) {
          return acc
        }
        acc[cur.venue.name][cur.resourceId].push(cur)
        return acc
      }, {})
    if (Object.keys(aggregated).length > 1) {
      // create filter row for venues
    }
    console.log(aggregated)
    const tables = []
    for (let i = 0; i < Object.keys(aggregated).length; i++) {
      const _r = []
      const venueKey = Object.keys(aggregated)[i]
      if (venueKey === 'undefined' || venueKey === 'default') continue
      const venueResources = filteredResources.filter((x) => x.venue === venueKey)
      let header = []
      header.push(<td>klo</td>)
      for (let k = 0; k < venueResources.length; k++) {
        const fl = venueResources[k].displayName.charAt(0)
        const d = venueResources[k].displayName.replace(/\D+/g, '')
        header.push(<td style={{ textAlign: 'center', paddingLeft: 5, paddingRight: 5, minWidth: 40 }}>{fl + d}</td>)
      }
      _r.push(header)
      const st = moment.utc(selecteddate + '00:00', 'YYYYMMDDHH:mm')
      const et = moment.utc(selecteddate + '23:59', 'YYYYMMDDHH:mm')
      let found = false
      while (st.isBefore(et)) {
        let timerow = []
        const timeRowTitle = st.format('HH:mm')
        timerow.push(<td style={{ paddingRight: 5, fontSize: 'small' }}>{timeRowTitle}</td>)
        for (let k = 0; k < venueResources.length; k++) {
          if (!aggregated[venueKey][venueResources[k].id]) {
            timerow.push(
              <td style={{ textAlign: 'center', backgroundColor: 'gray', borderWidth: 1, borderColor: 'darkgray' }}>
                &nbsp;
              </td>
            )
          } else {
            const re = aggregated[venueKey][venueResources[k].id].find(
              (r) =>
                r.data.state === 'reserved' &&
                st.utc().isBetween(moment.utc(r.start), moment.utc(r.end), undefined, '[)')
            )
            if (re) {
              timerow.push(
                <td style={{ textAlign: 'center', backgroundColor: 'green', borderWidth: 1, borderColor: 'darkgray' }}>
                  <div style={{ fontSize: 'x-small', height: 20, maxWidth: 60, overflow: 'hidden' }}>
                    {/* {re.data.title ? re.data.title : re.title} */}
                    &nbsp;
                  </div>
                </td>
              )
              found = true
            } else {
              timerow.push(
                <td style={{ textAlign: 'center', backgroundColor: 'gray', borderWidth: 1, borderColor: 'darkgray' }}>
                  &nbsp;
                </td>
              )
            }
          }
        }
        if (found) _r.push(timerow)
        st.add(30, 'minutes')
      }
      tables.push(
        <table className={venueKey}>
          {_r.map((row) => (
            <tr>{row}</tr>
          ))}
        </table>
      )
    }
    return tables //_r.map((row) => (<tr>{row}</tr>))
  }

  return (
    <>
      <TopNavigation />
      <Box sx={{ flexGrow: 1, marginLeft: 1, paddingBottom: 3 }}>
        <Box sx={{ marginTop: 2 }}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label={t('day')}
              value={selectedDate}
              onChange={(newValue) => {
                selectDate(newValue.format('YYYYMMDD'))
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        {/* <Typography>Aika: {selectedDate}</Typography> */}
        {/* <Box>{JSON.stringify(Object.keys(data))}</Box> */}
        <Box sx={{ marginTop: 1 }}>
          {isLoaded(data) ? createTable(data[selectedDate], data.resources, selectedDate) : ''}
        </Box>
      </Box>
    </>
  )
}

export default withBookings(BookingsScreen)
