import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './AppNavigator'
import FirebaseAnalytics from './FirebaseAnalytics'
// import CookieConsent from 'react-cookie-consent'

function App() {
  return (
    <BrowserRouter>
      <AppNavigator />
      <FirebaseAnalytics />
      {/*<CookieConsent
        location='bottom'
        buttonText='Ok'
        cookieName='fa-cookie-consent'
        style={{ background: '#7a7a7a', borderRadius: 5 }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px', borderRadius: 5 }}
        expires={150}
      >
        Käytämme . <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span>
      </CookieConsent> */}
    </BrowserRouter>
  )
}

export default App
