import React, { useState } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap'
import { Button } from '@mui/material'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { isEmpty } from 'react-redux-firebase'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import ReactFlagsSelect from 'react-flags-select'

// import logo from './assets/logo_evs.png'

const enhance = connect(({ firebase: { auth, profile } }) => ({
  auth,
  profile,
}))

function TopNavigation({ auth, profile }) {
  const { t, i18n } = useTranslation()
  let defaultLanguage = 'FI'
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const isLoggedIn = !isEmpty(auth)
  const [cookies, setCookie] = useCookies(['selectedLanguage'])
  if (cookies.selectedLanguage) {
    defaultLanguage = cookies.selectedLanguage
  }
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng.toLowerCase())
    setSelectedLanguage(lng)
    setCookie('selectedLanguage', lng, { path: '/', maxAge: 60000, secure: true })
  }

  return (
    <Navbar color='dark' dark expand='md'>
      <NavbarBrand onClick={() => history.push('/')}>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className='mr-auto' navbar>
          {isLoggedIn ? (
            <>
              <NavItem>
                <NavLink onClick={() => history.push('/reservations')}>{t('reservations')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => history.push('/lights')}>{t('lights')}</NavLink>
              </NavItem>
            </>
          ) : null}
        </Nav>
        <Nav className='ml-auto' navbar>
          {/* <div className='menu-flags'>
            <ReactFlagsSelect
              selected={selectedLanguage}
              onSelect={(code) => changeLanguage(code)}
              countries={['FI', 'GB']}
              customLabels={{
                FI: { primary: 'Suomi' },
                GB: { primary: 'English' }
              }}
              showSelectedLabel={true}
              showOptionLabel={true}
              selectedSize={16}
              selectButtonClassName='menu-flags'
              />
            </div> */}
          {isLoggedIn ? (
            <NavItem>
              <NavLink onClick={() => history.push('/login')}>{profile.email}</NavLink>
            </NavItem>
          ) : (
            <>
              <NavItem>
                {isOpen ? (
                  <NavLink onClick={() => history.push('/login')}>{t('kirjaudu')}</NavLink>
                ) : (
                  <Button onClick={() => history.push('/login')}>
                    {t('kirjaudu')}
                  </Button>
                )}
              </NavItem>
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default enhance(TopNavigation)
