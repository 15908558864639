import React from 'react'
// import HomeScreen from './screens/HomeScreen'
// import HomeScreenCal from './screens/HomeScreenCal'
import LogScreen from './screens/LogScreen'
import RegScreen from './screens/RegScreen'
import BookingsScreen from './screens/BookingsScreen'
import LightsScreen from './screens/LightsScreen'
import TestScreen from './screens/TestScreen'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  })
)

function PrivateRoute({ children, isLoggedIn, ...rest }) {
  // console.log('isLoggedIn', isLoggedIn)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const AppNavigator = ({ auth, profile }) => {
  // const auth = useSelector(state => state.firebase.auth)
  // const profile = useSelector(state => state.firebase.profile)
  const isLoggedIn = !isEmpty(auth)
  const authLoaded = isLoaded(auth)
  // console.log('authLoaded', auth)
  // console.log('profile', profile)
  if (!authLoaded) {
    return <div style={{ flex: 1 }}>Wait</div>
  } else {
    return (
      <Switch>
        <Route path='/login'>
          <LogScreen />
        </Route>
        <Route path='/register'>
          <RegScreen />
        </Route>
        <PrivateRoute path='/lights' auth={auth} isLoggedIn={isLoggedIn}>
          <LightsScreen />
        </PrivateRoute>
        <PrivateRoute path='/reservations' auth={auth} isLoggedIn={isLoggedIn}>
          <BookingsScreen />
        </PrivateRoute>
      </Switch>
    )
  }
}

export default enhance(AppNavigator)
