import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withFirestore, isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase'
import { falconInstance } from '../config'

const Todos = (props) => {
  const { dispatch, firestore, bookings, jack, selectedDate } = props
  console.log(props)
  const selectDate = (date) => {
    dispatch({
      type: 'UPDATE_DATE',
      payload: date,
    })
  }

  return (
    <div>
      <div
        onClick={() => {
          console.log('clicked')
          selectDate('20220117')
          // firestore.get()
        }}
      >
        Get Todos
      </div>
      <br />
      {!isLoaded(bookings)
        ? 'Loading'
        : isEmpty(bookings)
          ? 'Todo list is empty'
          : <div>{selectedDate} {JSON.stringify(bookings)} </div>}
    </div>
  )
}

export default compose(
  connect((state) => {
    let selectedDate = '20220115'
    if (state.dateselect.selectedDate) selectedDate = state.dateselect.selectedDate;
    return ({ selectedDate, auth: state.firebase.auth })
  }) ,
  // withFirestore,
  firestoreConnect((state) => [
    {
      collection: 'indexes',
      doc: 'bookings',
      subcollections: [{ collection: `${falconInstance.accountkey}`, doc: `${state.selectedDate}` }],
      storeAs: `${state.selectedDate}`,
    },
  ]),
  connect((state) => ({
    s: state.selectedDate,
    bookings: state.firestore.data,
    jack: 'bauer'
  }))
)(Todos)

//'20220115'
