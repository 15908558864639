import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

function reservationReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_RESERVATION': {
      const selected = action.payload
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_RESERVATION': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

function dateReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_DATE': {
      const selectedDate = action.payload
      console.log('selectedDate', selectedDate)
      return {
        ...state,
        selectedDate,
      }
    }
    case 'CLEAR_DATE': {
      return {
        ...state,
        selectedDate: null,
      }
    }
    default:
      return state
  }
}


function paymentReducer(state = [], action) {
  switch (action.type) {
    case 'UPDATE_PAYABLE_RESERVATION': {
      const selected = action.payload
      return {
        ...state,
        selected,
      }
    }
    case 'CLEAR_PAYABLE_RESERVATION': {
      return {
        ...state,
        selected: null,
      }
    }
    default:
      return state
  }
}

const appReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  reservation: reservationReducer,
  payable: paymentReducer,
  dateselect: dateReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
