import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useFirebase } from 'react-redux-firebase'

function FirebaseAnalytics() {
  const firebase = useFirebase()
  let location = useLocation()
  useEffect(() => {
    const analytics = firebase && firebase.analytics
    if (typeof analytics === 'function') {
      const page_path = location.pathname + location.search
      analytics().setCurrentScreen(page_path)
      analytics().logEvent('page_view', { page_path })
    }
  }, [location, firebase])
  return null
}

export default FirebaseAnalytics
