export const firebaseConfig = {
  apiKey: "AIzaSyB_aqXQI6DuVinrjeKX7doEHWEd-dIN1mQ",
  authDomain: "falcon-328a1.firebaseapp.com",
  databaseURL: "https://falcon-328a1.firebaseio.com",
  projectId: "falcon-328a1",
  storageBucket: "falcon-328a1.appspot.com",
  messagingSenderId: "640167253821",
  appId: "1:640167253821:web:8bd0d11aa501d630a59754",
  measurementId: "G-5YLCC5G2WK"
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
  // profileFactory: (userData, profileData, firebase) => { // how profiles are stored in database
  //   const { user } = userData
  //   return {
  //     email: user.email,
  //     firstname: user.firstname,
  //     lastname: user.lastname,
  //     created: user.created
  //   }
  // }
  // fileMetadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
  //   // upload response from Firebase's storage upload
  //   const {
  //     metadata: { name, fullPath }
  //   } = uploadRes
  //   // default factory includes name, fullPath, downloadURL
  //   return {
  //     name,
  //     fullPath,
  //     downloadURL
  //   }
}

export const falconInstance = {
  accountkey: 'tali-2GjtFLost8pDf0dWwmNc', //'OkG3UbJAqH3n7WGVIDJo',//'tali-2GjtFLost8pDf0dWwmNc',
  anonsignup: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { firebaseConfig, rrfConfig, falconInstance }