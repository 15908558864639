import React, { useState, useEffect } from 'react'
import { firebaseConnect, isEmpty, useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { Container, Grid, Paper, Box, TextField, Typography, Button } from '@mui/material'
import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { yellow, grey } from '@mui/material/colors'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import UpdateIcon from '@mui/icons-material/Update'
import TopNavigation from '../TopNavigation'
import DeleteIcon from '@mui/icons-material/Delete'
import AutoDeleteIcon from '@mui/icons-material/AutoDelete'
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import 'moment/locale/fi'
import { falconInstance } from '../config'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}))

// color: theme.palette.text.secondary,

const withResources = compose(
  connect((state) => ({
    auth: state.firebase.auth,
  })),
  firebaseConnect((state) => ([
    `/test/lights/${falconInstance.accountkey}`
  ])),
  firestoreConnect((state) => [
    {
      collection: 'customers',
      doc: `${falconInstance.accountkey}`,
      subcollections: [{ collection: 'resources' }],
      storeAs: 'resources',
    },
  ]),
  // connect(({ firestore }) => {
  //   if (firestore.data['bookings']) {
  // let _m = Object.keys(firestore.data['bookings'])
  //   .map((key) => {
  //     return {
  //       ...firestore.data['bookings'][key],
  //       id: key,
  //       selectkey: key,
  //     }
  //   })
  //   .filter((r) => r.locationid === falconInstance.accountkey && r.state !== 'user_cancelled')
  //   .filter((r) => {
  //     if (r.isrecurring) {
  //       return r.state !== 'cancelled'
  //     } else {
  //       return true
  //     }
  //   })
  //   .map((r) => {
  //     if (r.isrecurring) {
  //       return r.recurrencyDates.map((rec) => {
  //         let _r = JSON.parse(JSON.stringify(r))
  //         let _sKey = _r.selectkey + '-'
  //         if (typeof rec === 'object') {
  //           _r.occurency = { date: rec.date, state: rec.state }
  //           _sKey += rec.date
  //         } else {
  //           _r.occurency = { date: rec }
  //           _sKey += rec
  //         }
  //         _r.selectkey = _sKey // _r.selectkey + '-' + rec
  //         const timeOfDayHelper = moment.utc(_r.resourcegroups[0].end)
  //         const ordering = moment.utc(_r.occurency.date)
  //         ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
  //         if (moment().utcOffset() > 0) {
  //           ordering.subtract(moment().utcOffset(), 'minute')
  //         } else {
  //           ordering.add(moment().utcOffset(), 'minute')
  //         }
  //         _r.ordering = ordering.unix()
  //         return _r
  //       })
  //     } else {
  //       r.ordering = moment.utc(r.resourcegroups[0].end).unix()
  //       return r
  //     }
  //   })
  //   .flat(3)

  // _m.sort(function (a, b) {
  //   if (a.ordering < b.ordering) {
  //     return -1
  //   } else if (a.ordering > b.ordering) {
  //     return 1
  //   } else {
  //     return 0
  //   }
  // })
  // let _onsale = _m
  //   .filter((x) => {
  //     if (x.occurency) {
  //       return x.occurency.state === 'onsale'
  //     } else {
  //       return x.state === 'onsale'
  //     }
  //   })

  // let _past = _m
  //   .filter((x) => x.ordering < moment().unix())
  //   .filter((x) => {
  //     if (x.occurency) {
  //       if (x.occurency.state === 'moved') {
  //         return false
  //       }
  //     }
  //     return true
  //   })
  // let _upcoming = _m
  //   .filter((x) => x.ordering > moment().unix())
  //   .filter((x) => {
  //     if (x.occurency) {
  //       if (x.occurency.state === 'moved') {
  //         return false
  //       }
  //     }
  //     return true
  //   })
  //     return { bookings: firestore.data['bookings'] }
  //     // return { reservations: _upcoming, pastreservations: _past, onsale: _onsale, bookings: firestore.data['bookings'], owns: [] }
  //   } else {
  //     return { bookings: undefined }
  //   }
  // })
  connect((state) => {
    const filteredResources = state.firestore.data.resources
      ? Object.keys(state.firestore.data.resources)
          .map((key) => state.firestore.data.resources[key])
          .filter((x) => x.modbusid)
      : []
    filteredResources.sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1
      }
      if (a.displayName > b.displayName) {
        return 1
      }
      return 0
    })
    const lights = state.firebase.ordered.test && state.firebase.ordered.test.lights ? state.firebase.ordered.test.lights[falconInstance.accountkey] : []
    return {
      lights,
      resources: filteredResources,
    }
  })
)

const LightsScreen = ({ auth, dispatch, resources, lights }) => {
  const { t } = useTranslation()
  const firebase = useFirebase()

  const sendOnOff = async (command, resourceid, minutes) => {
    try {
      firebase.functions().region = 'europe-west1'
      const onoff = firebase.functions().httpsCallable('lights-onOff')
      return  await onoff({ command, resourceid, endtime: moment().add(minutes, 'minutes').format('YYYY-MM-DD HH:mm') })
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  const onVacuumClick = async (resourceid) => {
    const r = await sendOnOff('on', resourceid, 30)
  }

  const onGarbageClick = async (resourceid) => {
    const r = await sendOnOff('on', resourceid, 5)
  }


  return (
    <>
      <TopNavigation />
      <Box sx={{ flexGrow: 1, marginLeft: 1, paddingBottom: 3 }}>
        <Box sx={{ marginTop: 2 }}></Box>
        {/* <Typography>Aika: {selectedDate}</Typography> */}
        {/* <Box>{JSON.stringify(Object.keys(data))}</Box> */}
        <Box sx={{ marginTop: 1 }}>
          <Grid container spacing={2} direction='row' justifyContent='center' alignItems='center'>
            {isLoaded(resources) && isLoaded(lights)
              ? resources.map((r) => {
                let status = lights.find((x) => x.key === r.id)
                let lightsOn = false
                let lightIcon = <LightbulbIcon sx={{ color: grey[500], marginRight: 2 }}/>
                if (status && status.value.lightsstatus === 'on') {
                  lightIcon = <LightbulbIcon sx={{ color: yellow[500], marginRight: 2 }} />
                  lightsOn = true
                }

                return (
                  <>
                    <Grid item xs={3} textAlign={'right'}>
                      {r.displayName}
                    </Grid>
                    <Grid item xs={9}>
                      {lightIcon}
                      <Button size="small" disabled={lightsOn} variant="contained" style={{ marginRight: 20 }} onClick={() => onGarbageClick(r.id)} startIcon={<DeleteIcon />}>Garbage</Button>
                      <Button size="small" disabled={lightsOn} variant="contained" onClick={() => onVacuumClick(r.id)} startIcon={<AutoDeleteIcon />}>Vacuum</Button>
                    </Grid>
                  </>
                )
                })
              : ''}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default withResources(LightsScreen)
